<template>
    <div class="businessunitPanel">
        <b-tabs class="tabs-title">
            <b-tab title="Business unit" active>
                <perfect-scrollbar style="max-height: 550px">
                    <div style="padding: 20px" class="md-scrollbar">
                        <div v-if="loaded">
                            <form id="businessunitForm" ref="businessunitForm" @submit.prevent="submit">
                                <div class="md-list md-dense">
                                    <md-subheader>{{msg("Details")}}</md-subheader>
                                    <div class="row ">
                                        <div class="col-1 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary" style="padding: 0">person</md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-11">
                                            <div class="md-layout-item mr-2">
                                                <md-field :class="{ 'md-invalid': validate('name')}">
                                                    <label>{{msg("Company")}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="affiliate.company"/>
                                                    <span class="md-error" v-if="affiliate.company!= null &&!$v.affiliate.company.required">{{msg("Company is required")}}</span>
                                                </md-field>
                                            </div>

                                        </div>
                                    </div>
                                    <md-divider></md-divider>

                                    <md-subheader>{{msg("External")}}</md-subheader>
                                    <div class="row">
                                        <div class="col-1 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary"><i class="fas fa-at"></i></md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-11">
                                            <div class="md-layout-item">
                                                <md-field>
                                                    <label>{{msg("website")}}</label>
                                                    <md-input v-model="affiliate.website"/>
                                                </md-field>
                                            </div>
                                            <div class="md-layout-item">
                                                <md-field>
                                                    <label>{{msg("External Payment Link")}}</label>
                                                    <md-input v-model="businessunit.externalLink"/>
                                                </md-field>
                                            </div>
                                        </div>

                                    </div>
                                    <md-divider></md-divider>
                                </div>
                            </form>
                        </div>
                        <div v-else-if="this.error != null">
                            <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong>
                            </b-alert>
                        </div>
                        <div v-else style="min-height: 400px">
                            <div style="text-align: center;height: 100%;padding: 30% 20px 20px;">
                                <b-spinner label="Spinning"/>
                                {{msg("loading")}}
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

    import {mapActions, mapGetters, mapState} from "vuex";

    import {required} from 'vuelidate/lib/validators'
    import minLength from "vuelidate/src/validators/minLength";


    export default {
        name: "affiliatePanel",
        data() {
            return {
                loaded: false,
                error: null,
                affiliate: {
                    cur: "",
                    id: 0,
                    company: "",
                    externalLink:"",
                    website:"",
                },
                //Listeners
                listeners: {submit:()=>{}}
            }
        },
        props: {
            id: Number,
        },
        watch: {
            id: function () { // watch it
                this.onLoad();
                this.error = null;
            }
        },
        computed: {
            ...mapGetters('translator',['msg']),
            ...mapState({
                data: function (store) {
                    return store.data
                },
                user: function (store) {
                    return store.user
                },


            }),

        },
        created() {

            this.onLoad();
            this.$root.$on('crm::submitBusinessUnit', (this.listeners.submit = () => {
                this.submit();
            }));
        },
        beforeDestroy() {
            this.$root.$off('crm::submitBusinessUnit',this.listeners.submit)
        },
        methods: {
            ...mapActions('affiliate', {
                _getBusinessUnit: 'get',
                _updateBusinessUnit: 'update',
                _register: "register"
            }),
            onLoad() {
                this.loaded = false;
                if (this.id === 0 || typeof this.id !== "number") {
                    this.id = 0;
                    this.businessunit = {
                        id: 0,
                        creationTime: new Date(),
                        lang: 1,
                        buid: this.data.selectedBranchId,
                        representativeId: this.data.userId,
                        externalLink:"",
                        website:"",
                    };
                    this.loaded = true;
                } else
                    this.loadBusinessUnit();
            },
            loadBusinessUnit() {
                let $this = this;
                $this.loaded = false;
                this._getBusinessUnit({id: $this.id}).then((businessunit) => {
                    $this.businessunit = businessunit;
                    //Set page as loaded
                    this.loaded = true;
                }, (error) => {
                    $this.error = error;
                })
            },
            createBusinessUnit() {
                let $this = this;
                this._register({businessunit: this.businessunit}).then(
                    (a) => $this.$emit("submitted", {status: true, server: a}),
                    (a) => $this.$emit("submitted", {status: false, server: a})
                );
            },
            updateBusinessUnit() {
                let $this = this;
                this._updateBusinessUnit({id: this.id, businessunit: this.businessunit}).then(
                    (a) => $this.$emit("submitted", {status: true, server: a}),
                    (a) => $this.$emit("submitted", {status: false, server: a})
                );
            },
            validate(field) {
                return this.$v.businessunit[field] != null && this.$v.businessunit[field].$error === true;
            },
            submit(e) {

                if (typeof e === 'object' && typeof e.preventDefault === 'function')
                    e.preventDefault();


                if (this.$v.$invalid)
                    return;

                if (this.id !== 0)
                    return this.updateBusinessUnit();
                return this.createBusinessUnit();

            }

        },
        validations: {
            businessunit: {
                orgid: {
                    required,
                },
                name: {
                    minLength:minLength(4),
                },
            }
        }
    }
</script>

<style>


    .md-layout-item {
        margin: 0 5px;
    }

    .md-list > .row {
        min-height: 80px;
    }
</style>
