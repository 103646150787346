<template>
    <div id="affiliates">
        <md-card>
            <div class="card-header" style="overflow: hidden">
                <span class="card-title m-0">{{msg('Affiliate')}}</span>
                <div class="card-header-options">
                    <div @click="onAffiliateSelect(0);dialogs.affiliateDialog=true">
                        <md-button id="new" class="md-icon-button md-xmini md-theme-default">
                            <md-icon><i class="fas fa-plus"></i></md-icon>
                        </md-button>
                    </div>


                    <b-tooltip target="new" placement="left">
                        {{msg("New")}}
                    </b-tooltip>
                </div>
            </div>
            <!--Card Content-->
            <md-card-content>
                <!--search bar with accordion-->
                <div class="search-bar border">

                    <div class="search-bar-input">
                        <form @submit.prevent="filterUpdate" class="row">
                            <div class="col-12 row ml-1 mr-1">
                                <!--Id-->
                                <div class="col-2">
                                    <md-field>
                                        <label>{{msg('ID')}}</label>
                                        <md-input v-model="filter.id"/>
                                    </md-field>
                                </div>
                                <!--Name-->
                                <div class="col-2">
                                    <md-field>
                                        <label>{{msg('Company')}}</label>
                                        <md-input v-model="filter.company"/>
                                    </md-field>
                                </div>
                                <!-- &lt;!&ndash;Organization&ndash;&gt;
                                 <div class="col-2">
                                     <md-field>
                                         <label>{{msg('Login')}}</label>
                                         <md-select v-model="filter.orgid">
                                             <md-option v-for="o in allOrganizations" v-bind:value="o.id" v-bind:key="o.id">
                                                 {{ o.name }}
                                             </md-option>
                                         </md-select>
                                     </md-field>
                                 </div>
                                 &lt;!&ndash;Mail&ndash;&gt;
                                 <div class="col-2">
                                     <md-field>
                                         <label>{{msg('Mail')}}</label>
                                         <md-select  v-model="filter.mailid">
                                             <md-option value=""></md-option>
                                             <md-option v-for="m in allMails" v-bind:value="m.id" v-bind:key="m.id">
                                                 {{ m.alias }}
                                             </md-option>
                                         </md-select>
                                     </md-field>
                                 </div>
                                 &lt;!&ndash;Kyc&ndash;&gt;
                                 <div class="col-2">
                                     <md-field >
                                         <label>{{msg('Kyc')}}</label>
                                         <md-select v-model="filter.kycid">
                                             <md-option value=""></md-option>
                                             <md-option v-for="k in allKyc" v-bind:value="k.id" v-bind:key="k.id">
                                                 {{ k.name }}
                                             </md-option>
                                         </md-select>
                                     </md-field>
                                 </div>-->
                                <!--Currency-->

                            </div>
                        </form>
                    </div>
                </div>
                <!--Table-->
                <table class="table b-table table-hover" v-bind:class="{'table-striped':isLoaded}">
                    <thead>
                    <tr>
                        <th @click="sort('id')" aria-sort="none"><a>{{msg('Id')}}</a></th>
                        <th @click="sort('company')" aria-sort="none">{{msg('Company')}}</th>
                        <th @click="sort('phone')" aria-sort="none">{{msg('Phone')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!--Table-->
                    <template v-if="isLoaded">
                        <tr v-for="affiliate in allAffiliates" v-bind:key="affiliate.id">
                            <td @click="onAffiliateSelect(affiliate.id);dialogs.affiliateDialog=true">
                                {{affiliate.id}}
                            </td>
                            <td @click="onAffiliateSelect(affiliate.id);dialogs.affiliateDialog=true">
                                {{affiliate.company}}
                            </td>

                            <td @click="onAffiliateSelect(affiliate.id);dialogs.affiliateDialog=true">
                                {{affiliate.phone}}
                            </td>
                        </tr>
                    </template>
                    <!--Error-->
                    <template v-else-if="isLoaded !== true && dataError !== ''">
                        <tr class="table-loading">
                            <td colspan="9">
                                <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
                                    <strong>{{dataError}}</strong>
                                </b-alert>
                            </td>
                        </tr>
                    </template>
                    <!--Loading-->
                    <template v-else>
                        <tr class="table-loading">
                            <td colspan="9">
                                <div style="min-height: 300px;text-align: center">
                                    <div style="margin-top: 10%">
                                        <b-spinner label="Spinning"/>
                                        {{msg("loading")}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                <!--Components-->
                <!--Edit branch Modal -->
                <portal to="footer">
                    <b-modal no-fade v-model="dialogs.affiliateDialog" size="xl" ref="affiliate-modal"
                             id="affiliate-modal" :hide-footer="true" :hide-header="true" no-close-on-backdrop
                             centered>
                        <affiliatePanel ref="affiliatePanel" :id="selected" @submitted="refresh()"/>
                        <md-dialog-actions style="justify-content:center">
                            <b-button variant="outline-primary" class="btn-dialog mr-2"
                                      @click="dialogs.affiliateDialog=false">
                                {{msg("Close")}}
                            </b-button>
                            <b-button variant="outline-primary" class="btn-dialog" @click="submitAffiliate">
                                {{msg("Save")}}
                            </b-button>
                        </md-dialog-actions>
                    </b-modal>
                </portal>
            </md-card-content>
        </md-card>

        <!-- <div class="mt-3">
             <business-units-settings/>
         </div>-->
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import affiliatePanel from "../../components/panels/user/affiliatePanel";


    export default {
        name: "affiliates",
        components: {affiliatePanel},
        data() {
            return {

                selected: 0,
                loaded: false,
                error: '',
                filter: {},
                tableSort: {},
                //Dialogs to show
                dialogs: {affiliateDialog: false}
            }
        },
        watch: {
            filter: {
                deep: true,
                handler() {
                    this.refresh()
                }
            }
        },
        computed: {
            ...mapGetters('translator', ['msg']),
            ...mapGetters('affiliate', ['allAffiliates']),
            ...mapGetters('affiliate', {_isLoaded: "isLoaded", dataError: "getError"}),
            ...mapGetters('data', {_getCountry: 'getCountry'}),
            isLoaded() {
                return this._isLoaded;
            }
        },
        created() {
            let $this = this;
            this.loadData().then(() => {
                this.loaded = true;
            }, (error) => $this.error = error);
        },
        methods: {
            ...mapActions('affiliate', ['loadData', 'change', 'refresh']),
            ...mapActions('affiliate', {_refresh: 'refresh'}),
            refresh() {
                this._refresh({amountPerPage: 30, filter: this.filter})
            },
            onAffiliateSelect(id) {
                this.selected = id;
            },
            filterUpdate() {
                this.selectedRows = [];
                this.refresh()

            },
            sort(col) {
                if (this.tableSort.sortcolumn !== col) {
                    this.tableSort.sortby = 'asc';
                    this.tableSort.sortcolumn = col;
                    return;
                }
                this.tableSort.sortby = this.tableSort.sortby === 'asc' ? 'desc' : 'asc';
            },
            sortStatus(col) {
                if (this.tableSort.sortcolumn !== col)
                    return "none";
                return this.tableSort.sortby === 'asc' ? 'ascending' : 'descending'
            },
            submitAffiliate() {
                this.$root.$emit('crm::submitAffiliate')
            },
        }
    }
</script>

<style>

</style>
